import { useState } from "react"

import { TextField } from "@material-ui/core"

import { useDeleteApiClient } from "src/data/apiClients/apiClientQueries"
import { IApiClient } from "src/data/apiClients/apiClientTypes"
import { useTranslate } from "src/i18n/useTranslate"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"

export function DeleteApiClientDialog({
  client,
  onClose,
}: {
  client: IApiClient
  onClose: () => void
}) {
  const { t, langKeys } = useTranslate()
  const [clientName, setClientName] = useState("")
  const deleteApiClient = useDeleteApiClient()

  async function handleDelete() {
    await deleteApiClient.mutateAsync({ clientId: client.client_id })
    onClose()
  }

  return (
    <ConfirmDialog
      title={t(langKeys.api_clients_delete_title)}
      onClose={onClose}
      confirmButtonProps={{
        color: "destructive",
        disabled: clientName !== client.name,
      }}
      onConfirm={handleDelete}
      loading={deleteApiClient.isLoading}
      open={true}
    >
      <div>
        <p>{t(langKeys.api_clients_delete_body)}</p>
        <p>{t(langKeys.api_clients_delete_confirm_body)}</p>

        <TextField
          fullWidth
          onChange={(e) => setClientName(e.target.value)}
          placeholder={client.name}
        />
      </div>
    </ConfirmDialog>
  )
}
